import React, { Fragment, Suspense, lazy, useState, useEffect } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress,Typography } from "@material-ui/core/";

import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";

import services from "./services";

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));
const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

function App() {
  const {
    user: auth0User,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  // useEffect(() => {
  //   const user = AuthService.getCurrentUser();
  //   if (user) setUser(user);
  // }, [setUser]);

  // if (isLoading) {
  //   return (
  //     <>
  //       <CircularProgress size={70} />
  //       <Typography variant="caption" component="p" color="textPrimary">
  //         Cargando...
  //       </Typography>
  //     </>
  //   );
  // }

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated])

  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Pace color={theme.palette.primary.light} />
        <Suspense fallback={<Fragment />}>
          <Switch>
            <Route path="/admin">
              <LoggedInComponent user={auth0User} />
            </Route>
            <Route>
              <LoggedOutComponent />
            </Route>
          </Switch>
        </Suspense>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}
serviceWorker.register();
export default App;

import Employee from "../../../../model/point-of-sale/employee/employee.model";

const parseToEmployee = (pointOfSaleEmployee) => {
  return new Employee({
    id: pointOfSaleEmployee.id,
    name: pointOfSaleEmployee.name,
  });
};

export default {
  parseToEmployee,
};

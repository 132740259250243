// import Order from "../../../../model/point-of-sale/order/order.model";
// import OrderProduct from "../../../../model/point-of-sale/order/order.product.model";

import {
  getTotal,
} from "../../../../shared/functions/cartSummaryFunctions";

const { format } = require("date-fns");
const { addMinutes } = require("date-fns");


const PROVIDERS = {
  ERP: "ERP",
  ECOMMERCE: "ECOMMERCE",
};

const AVAILABLE_PAYMENT_METHODS = {
  cash: "cash",
  pos: "pos",
  qr: "pos",

}

const getDeliveryDate = (deliveryTimeInterval) => {
  const start = new Date();
  const end = addMinutes(start, 1);

  const deliveryDate = `${format(end, "LLL dd")}, ${format(start, "hh:mm a")} - ${format(end, "hh:mm a")}`;
  return deliveryDate;
}

const parseOrderProducts = (products) => {
  return products.map((product) => {
    const price = product.getPrice();
    const discountPrice = product.getDiscountPrice();
    return {
      quantity: product.quantity,
      qtyPerUnit: product.qtyPerUnit || 1,
      isVariablePrice: false,
      markAsDelete: false,
      markAsUpdate: false,
      itemPrice: discountPrice ? discountPrice : price,
      label: product.label,
      category: product.category,
      subCategory: product.subCategory,
      sku: product.sku,
      description: product.description,
      imageUrl: product.imageUrl,
      unit: product.unit,
      uidProduct: product.id,
      marketProductItemId: product.id,
    };
  });
};
const getSummary = (products) => {
  // const subTotal = getSubtotal(products);
  // const discount = getDiscountPrice(products);
  const total = getTotal(products);
  return {
    cashCollection: total,
    total,
    subTotal: total,
    deductionFromWallet: 0,
  };
};

const getCustomerUid = (customer) => {
  if (!customer) return null;
  if (!customer.provider) return null;
  if (customer.provider === PROVIDERS.ERP) return null;
  if (customer.provider === PROVIDERS.ECOMMERCE) return customer.commerceUid;
  return null;
};
const parseToOrder = (pointOfSaleOrder, employee) => {
  const products = parseOrderProducts(pointOfSaleOrder.products);
  const summary = getSummary(pointOfSaleOrder.products);
  const customerUid = getCustomerUid(pointOfSaleOrder.customer);

  const paymentOptionUid = AVAILABLE_PAYMENT_METHODS[pointOfSaleOrder.paymentMethod];

  const {deliveryTimeInterval} = pointOfSaleOrder.store
  const deliveryDate = getDeliveryDate(deliveryTimeInterval);

  const orderData = {
    serviceUid: pointOfSaleOrder.store.serviceProviderUid,
    storeId: pointOfSaleOrder.store.id,
    serviceName: pointOfSaleOrder.store.name,
    currencySymbol: "Bs",
    cashCollection: summary.cashCollection,
    total: summary.total,
    subTotal: summary.subTotal,
    deductionFromWallet: summary.deductionFromWallet,
    minimumChargeFee: pointOfSaleOrder.store.minimumChargeFee,
    deliveryCharge: 0,
    // deliveryEddress: "",
    hasPricing: pointOfSaleOrder.store.hasPricing,
    hasDelivery: false,
    items: products,
    paymentOptionUid,
    notes: `${pointOfSaleOrder.paymentMethod.toUpperCase()}:${employee.label}`,
    promiseTime: 1,
    deliveryDate,
    promoCode: null,
    discountType: null,
    discountValue: null,
    locale: "es",
    tips: 0,
  };
  if (customerUid) {
    orderData.commerceCustomerUid = customerUid;
  }
  return orderData;
};

export default {
  parseToOrder,
};

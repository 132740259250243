const data = [
  {
    erpId: "6217a1f5e9c18200123ce566",
    name: "Alejandro Bruno",
    phone: "+59176000070",
    email: "alejandrobrunob@gmail.com",
    nit: "6251801",
    socialReason: "claudia flores",
    commerceId: "60ce2c2125d2803e77be63e2",
    commerceUid: "e2FIoJW7S-e-70t8Mul-7g",
    customerPetProfileId: "72b1800f-ad2a-4e13-80ca-f2bed25d3fbc",
    provider: "EDDRESS",
    platform: "iOS",
    privateNote: null,
  },
  {
    erpId: "60ff1918617ac60011967636",
    name: "Andres Estenssoro",
    phone: "+59176678100",
    email: "andres.estenssoro@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "5e20b688eef3b0641ef5d417",
    commerceUid: "xIS4bjUfTJCwWlEh0j_c1A",
    customerPetProfileId: "507853e5-d4f8-4415-b7a4-f73ea78d8f0d",
    provider: "EDDRESS",
    platform: "Android",
  },
  {
    erpId: "610942dba2ac5a0011610bb0",
    name: "Andres Estenssoro",
    phone: "+59176678100",
    email: "andres.estenssoro@outlook.com",
    nit: "231",
    socialReason: "23",
    commerceId: "5e505a019d5d736de802e99c",
    commerceUid: "lvnbCSNUSxG-ynRxqgQ0Sw",
    customerPetProfileId: "0fd31eba-5da6-4432-ab0d-eef32eafa4ad",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "61128e6ca2ac5a0011610bbf",
    name: "Andres Estenssoro",
    phone: "+59176678100",
    email: "andresestenssoro@gmail.com",
    nit: "1212",
    socialReason: "prubea",
    commerceId: "5e10b302fd0a900da2e8fc4e",
    commerceUid: "P0tavToyQWS6GlO0-J462g",
    customerPetProfileId: "418e8386-9894-4de9-812e-2f103edc3826",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "612699b2aa9114001143acdb",
    name: "Anez",
    phone: "+59154887754",
    email: "rafa1@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "6126997de7827c06619c1441",
    commerceUid: "FF3pRB3BRrKXZlfQ_3EEWA",
    customerPetProfileId: "86225ce9-42bf-457d-8757-ca1b7ca90a06",
    provider: "EDDRESS",
    platform: "IOS",
  },
  {
    erpId: "6126a219aa9114001143acdc",
    name: "Anez",
    phone: "+59154545454",
    email: "rafa2@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "61269aa6e7827c06619c1444",
    commerceUid: "0OmswdWCSQqaIcQP_oOJcw",
    customerPetProfileId: "dcf4f539-2e8e-4178-8c1c-9edda92324a7",
    provider: "EDDRESS",
    platform: "IOS",
  },
  {
    erpId: "6126b61caa9114001143acdd",
    name: "Anez",
    phone: "+5915454545454",
    email: "rafa3@gmail.com",
    nit: "",
    socialReason: "",
    commerceId: "6126a71ee7827c06619c1449",
    commerceUid: "P8UfevRHSu6_bSHpDtDV1Q",
    customerPetProfileId: "7557bf16-967c-40bf-8333-5d1b6813f129",
    provider: "EDDRESS",
    platform: "IOS",
  },
  {
    erpId: "6126b8ffaa9114001143acde",
    name: "Anez",
    phone: "+59154545454",
    email: "rafa4@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "6126b6fbe7827c06619c144e",
    commerceUid: "eH-IhJESQF6lqz5zmMpWEg",
    customerPetProfileId: "b6175d29-b007-4514-ad0e-754c72fddccb",
    provider: "EDDRESS",
    platform: "IOS",
  },
  {
    erpId: "6127dc16aa9114001143ace2",
    name: "Anez",
    phone: "+5915454545454",
    email: "rafa12@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "6127daf839e8764fd45e0db5",
    commerceUid: "X0TP77RsTn2d_-dQPmdzPA",
    customerPetProfileId: "eff5bf70-dc86-4f48-90dd-a6aa6416a642",
    provider: "EDDRESS",
    platform: "IOS",
  },
  {
    erpId: "6127e870aa9114001143ace3",
    name: "Anez",
    phone: "+5915541121212",
    email: "rafa13@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "6127e36139e8764fd45e0dbc",
    commerceUid: "dZhedgSzT4CRjjb2_n1gHQ",
    customerPetProfileId: "190072e2-9073-4e1d-9580-1f1ebc5edcda",
    provider: "EDDRESS",
    platform: "IOS",
  },

  {
    erpId: "6172571e19c64b0013bb8087",
    name: "Arslan",
    phone: "+923225830401",
    email: "arslan@eddress.co",
    nit: "123",
    socialReason: "Tets",
    commerceId: "6100f987e637456228d12aeb",
    commerceUid: "bd8pKTHWRYa8lUldCugRrw",
    customerPetProfileId: "cd70281e-d0c5-4a8d-a733-ca9b48f03f7d",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "6183c7ce073bb8001168c758",
    name: "Awais Butt",
    phone: "+923216466444",
    email: "awais.b7solpk@gmail.com",
    nit: "",
    socialReason: "",
    commerceId: "616fe8010aed6b35c426df20",
    commerceUid: "wGDKJwbmQoCJuyY_InxN-w",
    customerPetProfileId: "6065e506-9713-43c0-9e1b-904e8796997c",
    provider: "EDDRESS",
    platform: "IOS",
  },
  {
    erpId: "6176b123073bb8001168c748",
    name: "Awais Riaz",
    phone: "+923127173797",
    email: "ariaz@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "616fc7b40aed6b35c426dee8",
    commerceUid: "ZXNjlEihS022FlCXBPHBKQ",
    customerPetProfileId: "51408316-e755-4583-a454-fe6327e6a8ff",
    provider: "EDDRESS",
    platform: "IOS",
  },
  {
    erpId: "615cd2982c233c001290c01c",
    name: "Bol EnvíosPet",
    phone: "+59170407545",
    email: "cuentas@enviospet.com",
    nit: "",
    socialReason: "",
    commerceId: "61015933dbf3b761ed91ba08",
    commerceUid: "S2musIwfQmifDPK91AR9og",
    customerPetProfileId: "ba254598-7c01-4a38-bbd7-3a229cebe582",
    provider: "EDDRESS",
    platform: "IOS",
  },
  {
    erpId: "618af2c993366600112eb062",
    name: "Bol EnvíosPet",
    phone: "+59170407545",
    email: "cuentas@enviospet.com",
    nit: "",
    socialReason: "",
    commerceId: "5e39c072b759f05bf2a52083",
    commerceUid: "1gNZd673QdiiG4BS4FJfzw",
    customerPetProfileId: "ee97c33a-ce83-46ad-8696-feae4ad2f3b5",
    provider: "EDDRESS",
    platform: "iOS",
  },
  {
    erpId: "620d102568e68800116dee88",
    name: "Edmund Hanna",
    phone: "+96171004810",
    email: "ehanna@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "605098ac276cc302223b8a09",
    commerceUid: "S1mHwKU3RmGoh0qQY_6pFQ",
    customerPetProfileId: "c52166f5-11bc-498f-8774-e398529e0710",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "60fb21e122fb61001221b450",
    name: "FABIO OJOPI MONASTERIO",
    phone: "+59178898008",
    email: "ojopif14@gmail.com",
    nit: "",
    socialReason: "",
    commerceId: "60074cddc1977715d318306d",
    commerceUid: "Wqp7M_EfR6Cwf5Mxf2Aj6w",
    customerPetProfileId: "83662964-18de-44d1-9af6-f7c1956d4cf7",
    provider: "EDDRESS",
    platform: "Android",
  },
  {
    erpId: "620b3f39199dc900123ca6aa",
    name: "Farooq Ahmed",
    phone: "+923145359555",
    email: "farooq@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "6154620bba690a7d52884bcd",
    commerceUid: "kfzpK_pUSSeX1jqiFxKbiQ",
    customerPetProfileId: "c8696df9-624a-4a64-a646-e0d4cc2a1afd",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "621f692e68bf930012913060",
    name: "France51 JET",
    phone: "+33333333333",
    email: "france51@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "621f59fda1fa971c54485f63",
    commerceUid: "I2n3ECU8TYyUSMqqVPx3vg",
    customerPetProfileId: "8b4cc9b0-f2f8-4850-aac8-69707d5668b8",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "610829dca2ac5a0011610ba3",
    name: "Gustavo Calderon",
    phone: "+59170407545",
    email: "g.calderonxd@gmail.com",
    nit: "",
    socialReason: "",
    commerceId: "6101675144ba47468a0dd392",
    commerceUid: "44EaLVftSMWTo1oVWteA1g",
    customerPetProfileId: "9fa2d3b9-6867-4d48-a658-0017bd18f314",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "615b02d55be02c00119d5e61",
    name: "Gustavo Calderon1",
    phone: "+59170407545",
    email: "g.calderonxd@gmail.com",
    nit: "",
    socialReason: "",
    commerceId: "61016577dbf3b761ed926813",
    commerceUid: "_3HvDyYDSt-UD3mHEY4-Bw",
    customerPetProfileId: "0a572547-a410-4f71-9dcf-c8359ce72aaf",
    provider: "EDDRESS",
    platform: "WEB",
    privateNote: "p1",
  },
  {
    erpId: "61fc3ceaff2a9700110f4c71",
    name: "Gustavo calderon",
    phone: "+59170407545",
    email: "gustavo.calderon.anez@gmail.com",
    nit: "",
    socialReason: "",
    commerceId: "6064c1befc8c4b167509f5ca",
    commerceUid: "aiEL2mamSfWZny8j0CBP4Q",
    customerPetProfileId: "4fb7d3a6-5b40-4c7e-9c9d-165c8ad6cdd3",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "61716fa719c64b0013bb8078",
    name: "HEALTHY",
    phone: "+9617488603",
    email: "mchammas@eddress.co",
    nit: "TEST",
    socialReason: "TEST",
    commerceId: "5ea06cc65be27e7236243e8e",
    commerceUid: "V3IzxZgeTuSe5nKTGZm9hA",
    customerPetProfileId: "ed3a1f53-5c01-407c-b356-5bd7d8e67409",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "61168847fd33110011a45268",
    name: "HEALTHY666777888",
    phone: "+96176488603",
    email: "mchammas@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "610160e344ba47468a0dd389",
    commerceUid: "nQK_N8WUTU-61XVd4q8b4g",
    customerPetProfileId: "aaa7d3b1-9cc0-4cbe-883f-b6150a4caa12",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "6123bb294fff730011a4da73",
    name: "Juan Javier Estenssoro",
    phone: "+591+59177812222",
    email: "jjestenssoro@gmail.com",
    nit: "",
    socialReason: "",
    commerceId: "5df009b9700edf1c481192f3",
    commerceUid: "LJxu3AdKT6qmJgyN2gtOqw",
    customerPetProfileId: "f48f51bd-ef6e-429b-8f54-a2ede9711931",
    provider: "EDDRESS",
    platform: "Android",
  },
  {
    erpId: "6123f9374fff730011a4da7a",
    name: "Julio Luna Pizarro",
    phone: "+59172844996",
    email: "julio_lunap@hotmail.com",
    nit: "",
    socialReason: "",
    commerceId: "5e44a322f2742479f677c3cd",
    commerceUid: "y1Wj2i_dTx-b3RoF7h7ccA",
    customerPetProfileId: "2c17961a-179e-4a39-9df2-ed6972490eea",
    provider: "EDDRESS",
    platform: "Android",
  },

  {
    erpId: "61a78d5a94c8a90011878b4f",
    name: "Kamal Nasir",
    phone: "+923345505189",
    email: "kamal@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "61a78ccfccd0dc43f48d9ec2",
    commerceUid: "XTR_LZb9SDG_T-kCA7ryVw",
    customerPetProfileId: "976c90cb-1605-4dfc-95d9-6de70da23993",
    provider: "EDDRESS",
    platform: "IOS",
    privateNote: null,
  },
  {
    erpId: "614ae3480325ae0012ec8cc1",
    name: "Khizra",
    phone: "+923315570734",
    email: "khizra@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "60701399ca65d06b10e9b02c",
    commerceUid: "gr4M0CWWQbGzDtS_FYYp1w",
    customerPetProfileId: "22ae8250-ff9b-405f-9600-f9e61a64c971",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "621ce6eb68bf93001291305f",
    name: "Mr Meenee",
    phone: "+923185444651",
    email: "mbc000@live.com",
    nit: "",
    socialReason: "",
    commerceId: "62173968aad9eb692a4e60a1",
    commerceUid: "3PHOO89_TVyJRFXdKKvvMg",
    customerPetProfileId: "69331a66-0746-4c8b-8525-aae729daa25d",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "61cac1791466330011dd58db",
    name: "Muhammad Arif Nazir",
    phone: "+923335485716",
    email: "marif@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "61cac0e49c69ed54253080ff",
    commerceUid: "TmNGO245SSeW08TcBOHMMg",
    customerPetProfileId: "c0b2661d-c065-4935-aed7-f2d88fc66f1f",
    provider: "EDDRESS",
    platform: "IOS",
    privateNote: null,
  },
  {
    erpId: "6206816bff2a9700110f4ca7",
    name: "Muhammad Arif Nazir",
    phone: "+923335485715",
    email: "marif@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "6205679ede4b3e5a8c7d814e",
    commerceUid: "5GUbwnNoSeSefpWfRJe68A",
    customerPetProfileId: "7734c16f-5314-49c9-aa12-fd4f0ded0d7d",
    provider: "EDDRESS",
    platform: "IOS",
    privateNote: null,
  },
  {
    erpId: "617a9baa073bb8001168c757",
    name: "Muhammad Jamil",
    phone: "+92545415424",
    email: "mjamil@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "6101792edbf3b761ed937755",
    commerceUid: "gAiqgJkfSQ--nSwYb2CI_w",
    customerPetProfileId: "d4c730c5-6891-4684-9707-0f2d7c15c899",
    provider: "EDDRESS",
    platform: "IOS",
  },
  {
    erpId: "6217b2e6e9c18200123ce590",
    name: "Prueba Rafael",
    phone: "+5918556632",
    email: "cuentadepruebarafael@gmail.com",
    nit: "3434343434",
    socialReason: "Prueba Rafael",
    commerceId: "5f467d991c142405cff59acd",
    commerceUid: "YoErUzawRxqovUXr9e5L1Q",
    customerPetProfileId: "cd1b0357-04b3-4311-be97-a32ddd2bf5bd",
    provider: "EDDRESS",
    platform: "Web",
    privateNote: null,
  },
  {
    erpId: "60ff1847617ac60011967634",
    name: "Prueba Rafael 307",
    phone: "+59167811785",
    email: "rafael.anez.sa307@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "607da40b30111f701e55291d",
    commerceUid: "T7L0s1E_RuGd8YHILCvKZg",
    customerPetProfileId: "aea38163-c7ac-410a-8d70-d993cc5c30e1",
    provider: "EDDRESS",
    platform: "Android",
  },
  {
    erpId: "61118a81a2ac5a0011610bb8",
    name: "Prueba Rafael 307",
    phone: "+59167812908",
    email: "rafael.anez.sa307@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "604a26c2109cf51e9ccd64d8",
    commerceUid: "9YpMxdKARx6Zh2usGJ1rKQ",
    customerPetProfileId: "5a05ffe9-0e2d-40d4-9390-473eddb4dfce",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "6123eadc4fff730011a4da78",
    name: "Prueba Rafael 308",
    phone: "+59167811273",
    email: "rafael.anez.sa308@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "604a3276109cf51e9ccd64e7",
    commerceUid: "-8H9H0PIS02OeSsfWVjUHg",
    customerPetProfileId: "402eff3f-68e1-43e4-8735-af7a9f6d24cf",
    provider: "EDDRESS",
    platform: "Web",
  },
  {
    erpId: "6179a985073bb8001168c756",
    name: "RAfael 311",
    phone: "+5915757575",
    email: "rafael.anez.sa311@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "6103fe4cc4eb3f46cc81b694",
    commerceUid: "YqHscByYS3WS1nny8xG3Yg",
    customerPetProfileId: "5a62e0ae-c858-4058-8015-a9418cbfd1bd",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "61fdf185ff2a9700110f4c86",
    name: "Rafa",
    phone: "+59164445545",
    email: "rafapruebas4@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "61fdf161802d7759dfbc221f",
    commerceUid: "d2sqbHnEQ5yCewq9jSNcuw",
    customerPetProfileId: "448a2c50-5143-450a-9e25-250f2d7382b9",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "618e5b6b56c0e60012232e14",
    name: "Rafa 10 Pruebas",
    phone: "+59167845525",
    email: "rafapruebas10@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "618e5b525f0fd9314a0b7959",
    commerceUid: "7gAHgqJTSQK07soEfxZfFQ",
    customerPetProfileId: "9981c41d-80b3-4cb5-9e3a-aab69afb5b9e",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "6193fba560d64900111c25d2",
    name: "Rafa 100 pruebas",
    phone: "+5917776666",
    email: "rafapruebas100@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "6193e7787d9259409d527e1b",
    commerceUid: "c9nJGOsZTGGpOOU39wG3Wg",
    customerPetProfileId: "f435d802-2f63-42c0-bb77-af2a786315d9",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "61ae88381466330011dd58c5",
    name: "Rafa Pruebas 101",
    phone: "Sin teléfono",
    email: "rafapruebas101@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "61ae81898614f33332f0848b",
    commerceUid: "u8fRkvrZTHq2xEz4gJA5WQ",
    customerPetProfileId: "96c98d8e-df87-452d-a44d-37632a281a70",
    provider: "EDDRESS",
    platform: "WEB",
    privateNote: "notas",
  },
  {
    erpId: "6196650b60d64900111c25d3",
    name: "Rafa Pruebas 6",
    phone: "+591686868686",
    email: "rafapruebas6@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "618974662da3727b9f8d7da0",
    commerceUid: "KGHjvRCbSwmu378_r6COEw",
    customerPetProfileId: "20a5af0e-03e8-471c-8b4f-28bb3efbfb70",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "61897d2493366600112eb055",
    name: "Rafa Pruebas 8",
    phone: "+59164545454",
    email: "rafapruebas8@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "61897d022da3727b9f8d7da6",
    commerceUid: "Ut-nwuKMTzuYh5LElMhKHw",
    customerPetProfileId: "b62fb2d5-11eb-45ca-9316-96f4b528af08",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "615c66479abc9a00116120af",
    name: "Rafa pruebas",
    phone: "+59167811273",
    email: "rafapruebas@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "615c660cfc5853798becc920",
    commerceUid: "YT5vo5fzTDejtcaE5CY9wQ",
    customerPetProfileId: "7ec160c3-71c5-4490-9066-75c8d9a88939",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "61e8235dff2a9700110f4c51",
    name: "Rafa pruebas",
    phone: "+59164848484",
    email: "rafapruebas@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "61dc97a47c708958702cd874",
    commerceUid: "mQKUchiST-K5EWTRyBoV7w",
    customerPetProfileId: "9e69da6a-4096-4de9-85de-d9576117552c",
    provider: "EDDRESS",
    platform: "WEB",
    privateNote: null,
  },
  {
    erpId: "61609b33826a100012961228",
    name: "Rafa pruebas 2",
    phone: "+59157575757",
    email: "rafapruebas2@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "615f47ae8bf8ac4c6b33cefb",
    commerceUid: "mVXtsQRrTNWNtli-hH5PYg",
    customerPetProfileId: "71726679-f719-4d6e-852b-c08e599d28de",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "61fdf239ff2a9700110f4c89",
    name: "Rafa pruebas 500",
    phone: "+59167884454",
    email: "rafapruebas400@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "61b8e022235d1700027d1f83",
    commerceUid: "OIEjz2ZeSESutTbGV8pCEA",
    customerPetProfileId: "895df957-66ea-49a1-88c5-788baad6c1e4",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "6127da14aa9114001143ace1",
    name: "Rafa11",
    phone: "+59164557787",
    email: "rafa11@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "6127d9e639e8764fd45e0db2",
    commerceUid: "c4k8tTnVRJqxOyrvMeKQpA",
    customerPetProfileId: "7ba00971-b22f-4aba-a85f-c4259e6aef0c",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "612ce844aa9114001143acea",
    name: "Rafa16",
    phone: "+59176558312",
    email: "rafa16@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "612ce8199b63c4115e75fc1c",
    commerceUid: "1d0wNY1TT06sOgQufHNJ_Q",
    customerPetProfileId: "ad393783-567a-42ab-8946-866ca3ea1872",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "612cf0f4aa9114001143aceb",
    name: "Rafa17",
    phone: "+59169805323",
    email: "rafa17@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "612ce86e9b63c4115e75fc1f",
    commerceUid: "Wh00-ARjQjahWgKHx4234g",
    customerPetProfileId: "6540056f-2f1f-4ff1-9678-b9b3d651e2cd",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "615f6bd3cf8d740011d6da66",
    name: "Rafael",
    phone: "+59164589632",
    email: "rafael.anez.sa96@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "5ec5dadfac778c2d66c2e671",
    commerceUid: "VGyBPq9UT3WROrKK3J3s7g",
    customerPetProfileId: "8a7e718c-7050-4b11-9f40-794f6d501928",
    provider: "EDDRESS",
    platform: "Web",
  },
  {
    erpId: "61097af3a2ac5a0011610bb4",
    name: "Rafael 10000",
    phone: "+59167811273",
    email: "rafael.anez.sa10000@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "61097aba6c004862115612ee",
    commerceUid: "JvxBRY_jQtSytqJQfZuYfg",
    customerPetProfileId: "3c80c349-6176-4c7d-b11c-34602ffaa09f",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "61119da9a2ac5a0011610bba",
    name: "Rafael 3000",
    phone: "+59164587731",
    email: "rafael.anez.sa3000@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "61119d840bb93669f98688bc",
    commerceUid: "gJ7QwrKSTsehRKfoESz7Mw",
    customerPetProfileId: "85992807-5439-4bf5-aa8f-b9e730f3d539",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "61799244073bb8001168c755",
    name: "Rafael 310",
    phone: "+59167811273",
    email: "rafael.anez.sa310@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "6073c6ff9126997b27ffb53b",
    commerceUid: "OFZzcqDZQMW7FZh0GPm4lw",
    customerPetProfileId: "cba4b954-082b-47ce-9b8f-b871bc0dd0f6",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "61fdf200ff2a9700110f4c88",
    name: "Rafael 325",
    phone: "+59165595959",
    email: "rafael.anez.sa325@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "61fdf1e9802d7759dfbc2222",
    commerceUid: "-mO9YwlDSwWfwvbetoe-5w",
    customerPetProfileId: "a55e6a64-5b66-4802-a5f6-39c0a665266a",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "61097a48a2ac5a0011610bb2",
    name: "Rafael 400",
    phone: "+59164578781",
    email: "rafael.anez.sa300@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "60e31f9a10bcf86025fa15c9",
    commerceUid: "opAbstesTbymKioapvFQtg",
    customerPetProfileId: "85ebf192-c3ea-4857-be88-8fa2d8cd2c3b",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "61fdf1c6ff2a9700110f4c87",
    name: "Rafael 400",
    phone: "+59167811273",
    email: "rafael.anez.sa300@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "60c7d30203c8dd5bfe8160cc",
    commerceUid: "KkSLMZ2wSY6vPZg_rjEGMQ",
    customerPetProfileId: "f2b6bdab-6b09-4d7b-a7bd-6ed446822b50",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "61097a8aa2ac5a0011610bb3",
    name: "Rafael 600",
    phone: "+59167811273",
    email: "rafael.anez.sa600@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "60c0f66483bb7d32d508f268",
    commerceUid: "GxQ3-Ql2S566N38Ujeb4qA",
    customerPetProfileId: "3993965f-dd61-4df3-b52d-8c771028c718",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "61127903a2ac5a0011610bbc",
    name: "Rafael 900",
    phone: "+5914324341234",
    email: "rafael.anez.sa900@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "60d369c2153c9f5bfa155a88",
    commerceUid: "AnZzx7iXTLGW9GcXEguAaA",
    customerPetProfileId: "4ccf32c0-4928-4642-87cf-dbe6a80abbdc",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "619f95ce07237f001181a331",
    name: "Rafael Anez",
    phone: "+59167811273",
    email: "rafae.anez@enviospet.com",
    nit: "",
    socialReason: "",
    commerceId: "619f94df5373e1042a374997",
    commerceUid: "LSY5tsaXRTicOyaQlnHuvQ",
    customerPetProfileId: "e3437180-6698-4608-8473-01cf7b0a129b",
    provider: "EDDRESS",
    platform: "WEB",
    privateNote: "Pruebas 1 3",
  },
  {
    erpId: "61fc3e08ff2a9700110f4c72",
    name: "Rafael Anez",
    phone: "+59168778878",
    email: "rafael.anez@enviospet.com",
    nit: "",
    socialReason: "",
    commerceId: "61fc3dd380e8b968eef6593c",
    commerceUid: "2aQroVTDRZuzB15wf-ws0A",
    customerPetProfileId: "e08ae12d-163e-47b8-99aa-d9948dab9291",
    provider: "EDDRESS",
    platform: "WEB",
    privateNote: null,
  },
  {
    erpId: "60e312bb0b82730011b56047",
    name: "Rafael Añez Salvatierra",
    phone: "+59167811273",
    email: "rafael.anez.sa@outlook.com",
    nit: "5853877",
    socialReason: "Rafael Añez Salvatierra",
    commerceId: "dxzBrv-CQmajGyEZo8xmxg",
    commerceUid: "dxzBrv-CQmajGyEZo8xmxg",
    customerPetProfileId: "b22e482a-3732-472a-9e1d-712a4cd4e0c0",
    provider: "EDDRESS",
    platform: "bla",
  },
  {
    erpId: "61080879a2ac5a0011610b8d",
    name: "Rafael Añez Salvatierra",
    phone: "+59167811273",
    email: "rafael.anez.sa@gmail.com",
    nit: "58538773",
    socialReason: "Juan Javier",
    commerceId: "5e064cd41d87b118b3b2ad41",
    commerceUid: "dxzBrv-CQmajGyEZo8xmxg",
    customerPetProfileId: "b22e482a-3732-472a-9e1d-712a4cd4e0c0",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "615f643ecf8d740011d6da64",
    name: "Rafael Añez Salvatierra",
    phone: "+59167855252",
    email: "rafael.anez.sa@outlook80.com",
    nit: "",
    socialReason: "",
    commerceId: "5edfee5281831a2c3be3c625",
    commerceUid: "m2thhccORZevDWP3Q4KG0Q",
    customerPetProfileId: "46ac4869-308a-4291-bfe3-79adb74de1f9",
    provider: "EDDRESS",
    platform: "Web",
  },
  {
    erpId: "61659825826a10001296122a",
    name: "Rafael Añez Salvatierra",
    phone: "+59167811273",
    email: "rafael.anez.sa@outlook.com",
    nit: "5853877",
    socialReason: "Rafael Añez Salvatierra",
    commerceId: "5e2761aa4224ce5e40917093",
    commerceUid: "Wx8isdnhRoCEgKXZ2cZCFg",
    customerPetProfileId: "119f3fb6-87e3-42cb-87f1-26849cd0931d",
    provider: "EDDRESS",
    platform: "ANDROID",
  },
  {
    erpId: "619f91b207237f001181a32c",
    name: "Rafael Pruebas 3",
    phone: "+5914466464",
    email: "rafapruebas3@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "61783a993dd6cf2334873580",
    commerceUid: "SEaPg4AsQZmxRTBHQHOT1g",
    customerPetProfileId: "2b1ba5fa-a0d8-4191-8738-afbceb23711e",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "61d3e8bd1466330011dd58dc",
    name: "Saika Latif",
    phone: "+923012181925",
    email: "slatif@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "5f3270e814364353ee66aed3",
    commerceUid: "dHykQosgSMWUqrNSBstrbQ",
    customerPetProfileId: "23209d49-ede0-4c7c-894c-cce680afa496",
    provider: "EDDRESS",
    platform: "IOS",
    privateNote: null,
  },
  {
    erpId: "62027fe5ff2a9700110f4c95",
    name: "Sergio Luis Arduz Gabriel",
    phone: "+59175023315",
    email: "arduzer@gmail.com",
    nit: "7808820012",
    socialReason: "Sergio Arduz",
    commerceId: "5eff3bd7cb86110d3684e134",
    commerceUid: "DdzuhjqIQtCCtrmHRbKbqw",
    customerPetProfileId: "f969c091-2057-4949-a819-580eba9fcb69",
    provider: "EDDRESS",
    platform: "WEB",
    privateNote: null,
  },
  {
    erpId: "61c165161466330011dd58da",
    name: "Shamas Ulhaq",
    phone: "+923485897121",
    email: "shamasulhaq3333@gmail.com",
    nit: "",
    socialReason: "",
    commerceId: "617a900d13827d376ac2be03",
    commerceUid: "kRubXDcMTKOZyFByqW3GAw",
    customerPetProfileId: "24c638b9-9d46-41e9-8575-0a6ad9e5faa6",
    provider: "EDDRESS",
    platform: "IOS",
    privateNote: null,
  },
  {
    erpId: "61d8811a1466330011dd58dd",
    name: "Test",
    phone: "+923429722430",
    email: "test@test.co",
    nit: "",
    socialReason: "",
    commerceId: "61d82e873055db5ef204427c",
    commerceUid: "g-gzkjdnQdSKa8cejJMtlQ",
    customerPetProfileId: "3d3f6201-260f-41d8-a1a2-ee42d786c613",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "6225cb6dffe841001173a988",
    name: "Usman KIANI QA",
    phone: "+923125702489",
    email: "ukiani@eddress.co",
    nit: "",
    socialReason: "",
    commerceId: "61f925418b5f6330d52b87f3",
    commerceUid: "K656xDN7SrubN8tMG1pbJg",
    customerPetProfileId: "570fb6c9-747c-4c77-b0a3-026556ebe09c",
    provider: "EDDRESS",
    platform: "ANDROID",
    privateNote: null,
  },
  {
    erpId: "60ed97e4c67aac00117c8ed9",
    name: "andres estenssoro",
    phone: "+59176678100",
    email: "andresestenssoro@gmail.com",
    nit: "2992383",
    socialReason: "pedro juan",
    commerceId: "P0tavToyQWS6GlO0-J462g",
    commerceUid: "P0tavToyQWS6GlO0-J462g",
    customerPetProfileId: "418e8386-9894-4de9-812e-2f103edc3826",
    provider: "EDDRESS",
    platform: null,
  },
  {
    erpId: "6127e934aa9114001143ace4",
    name: "anez",
    phone: "+591554545454",
    email: "rafa14@outliok.com",
    nit: "",
    socialReason: "",
    commerceId: "6127e8b536412c2e572f83f6",
    commerceUid: "6eV6K9-oSmSQ33SU0_-7vw",
    customerPetProfileId: "33c6d09a-7c36-43b8-8638-0e45bbcb817c",
    provider: "EDDRESS",
    platform: "IOS",
  },
  {
    erpId: "6189816393366600112eb056",
    name: "rafa prueba 1",
    phone: "+5915454545",
    email: "rafael.anez.prueba.1@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "618981022da3727b9f8d7da9",
    commerceUid: "I6w3QgXjTWm7dtcyUmGtcQ",
    customerPetProfileId: "99b98dc4-f979-4a63-b635-7d05b087f507",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "6126c34baa9114001143acdf",
    name: "rafa10",
    phone: "+59157575757",
    email: "rafa10@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "6126c2f8e7827c06619c1456",
    commerceUid: "gsMbGnleSMiIDNBIpIoATw",
    customerPetProfileId: "c33b6dc3-f7f2-4d88-9580-fc3a5e88fe0a",
    provider: "EDDRESS",
    platform: "WEB",
  },
  {
    erpId: "615afc475be02c00119d5e60",
    name: "rafa10",
    phone: "+591678855678",
    email: "rafa10@outlook.com",
    nit: "",
    socialReason: "",
    commerceId: "612cdf39c5ab69652c5af25d",
    commerceUid: "m2XF5O_rQfKWT4dua-wf2w",
    customerPetProfileId: "5ffe7312-dc92-461b-9b80-862ec2e68deb",
    provider: "EDDRESS",
    platform: "WEB",
  },
];

export { data };

import Store from "../../../../model/point-of-sale/store/store.model";

const parseToStore = (pointOfSaleStore) => {
  return new Store({
    id: pointOfSaleStore.id,
    serviceProviderUid: pointOfSaleStore.serviceProviderUid,
    operationUid: pointOfSaleStore.operationUid,
    country: pointOfSaleStore.country,
    paymentOptions: pointOfSaleStore.paymentOptions,
    marketStoreSections: pointOfSaleStore.marketStoreSections,
    areaIds: pointOfSaleStore.areaIds,
    schedule: pointOfSaleStore.schedule,
    storeSchedules: pointOfSaleStore.storeSchedules,
    deliveryTimeInterval: pointOfSaleStore.deliveryTimeInterval,
    prepTime: pointOfSaleStore.prepTime,
    hasPricing: pointOfSaleStore.hasPricing,
    hasItemImages: pointOfSaleStore.hasItemImages,
    isTimeRequired: pointOfSaleStore.isTimeRequired,
    isActive: pointOfSaleStore.isActive,
    label: pointOfSaleStore.label,
    viewType: pointOfSaleStore.viewType,
    slug: pointOfSaleStore.slug,
    minimumCharge: pointOfSaleStore.minimumCharge,
    minimumChargeFee: pointOfSaleStore.minimumChargeFee,
    deliveryCharge: pointOfSaleStore.deliveryCharge,
    deliveryFeeMultiplier: pointOfSaleStore.deliveryFeeMultiplier,
    etaMultiplier: pointOfSaleStore.etaMultiplier,
    minimumTicketFreeDelivery: pointOfSaleStore.minimumTicketFreeDelivery,
    imageVersion: pointOfSaleStore.imageVersion,
    sortOrder: pointOfSaleStore.sortOrder,
    name: pointOfSaleStore.name,
    phoneNumber: pointOfSaleStore.phoneNumber,
    eddress: pointOfSaleStore.eddress,
    eddressCode: pointOfSaleStore.eddressCode,
    showInApp: pointOfSaleStore.showInApp,
    isDeleted: pointOfSaleStore.isDeleted,
    messagingUid: pointOfSaleStore.messagingUid,
    timeInterval: pointOfSaleStore.timeInterval,
    commission: pointOfSaleStore.commission,
    timeZone: pointOfSaleStore.timeZone,
    deliverWith: pointOfSaleStore.deliverWith,
    isSchedulesEnabledForCollectionsGroupsProducts:
      pointOfSaleStore.isSchedulesEnabledForCollectionsGroupsProducts,
    openingHours: pointOfSaleStore.openingHours,
    telegramSubscription: pointOfSaleStore.telegramSubscription,
    training: pointOfSaleStore.training,
    active: pointOfSaleStore.active,
    deleted: pointOfSaleStore.deleted,
  });
};

export default {
  parseToStore,
};

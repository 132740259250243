export const getSubtotal = (cartProducts) => { 
  return cartProducts.reduce((sum, product) => {
    const price = product.getPrice();
    const amount = price;
    return sum + amount * product.quantity;
  }, 0);
};

export const getDiscountPrice = (cartProducts) => {
  return cartProducts.reduce((sum, product) => {
    const price = product.getPrice();
    const discountPrice = product.getDiscountPrice();
    const discountAmount = discountPrice || 0;
    if (discountAmount === 0) return sum;
    return sum + (price - discountAmount) * product.quantity;
  }, 0);
};

export const getDiscount = (cartProducts) => {
  const subTotal = getSubtotal(cartProducts);
  const discountPrice = getDiscountPrice(cartProducts);

  if (discountPrice === 0) return 0;

  return subTotal - discountPrice;
};

export const getTotal = (cartProducts) => {
  const subTotal = getSubtotal(cartProducts);
  const discount = getDiscountPrice(cartProducts);
  return subTotal - discount;
};

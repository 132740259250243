class Store {
  constructor({
    id,
    serviceProviderUid,
    operationUid,
    country,
    paymentOptions,
    marketStoreSections,
    areaIds,
    schedule,
    storeSchedules,
    deliveryTimeInterval,
    prepTime,
    hasPricing,
    hasItemImages,
    isTimeRequired,
    isActive,
    label,
    viewType,
    slug,
    minimumCharge,
    minimumChargeFee,
    deliveryCharge,
    deliveryFeeMultiplier,
    etaMultiplier,
    minimumTicketFreeDelivery,
    imageVersion,
    sortOrder,
    name,
    phoneNumber,
    eddress,
    eddressCode,
    showInApp,
    isDeleted,
    messagingUid,
    timeInterval,
    commission,
    timeZone,
    deliverWith,
    isSchedulesEnabledForCollectionsGroupsProducts,
    openingHours,
    telegramSubscription,
    training,
    active,
    deleted,
  }) {
    this.id = id;
    this.serviceProviderUid = serviceProviderUid;
    this.operationUid = operationUid;
    this.country = country;
    this.paymentOptions = paymentOptions;
    this.marketStoreSections = marketStoreSections;
    this.areaIds = areaIds;
    this.schedule = schedule;
    this.storeSchedules = storeSchedules;
    this.deliveryTimeInterval = deliveryTimeInterval;
    this.prepTime = prepTime;
    this.hasPricing = hasPricing;
    this.hasItemImages = hasItemImages;
    this.isTimeRequired = isTimeRequired;
    this.isActive = isActive;
    this.label = label;
    this.viewType = viewType;
    this.slug = slug;
    this.minimumCharge = minimumCharge;
    this.minimumChargeFee = minimumChargeFee;
    this.deliveryCharge = deliveryCharge;
    this.deliveryFeeMultiplier = deliveryFeeMultiplier;
    this.etaMultiplier = etaMultiplier;
    this.minimumTicketFreeDelivery = minimumTicketFreeDelivery;
    this.imageVersion = imageVersion;
    this.sortOrder = sortOrder;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.eddress = eddress;
    this.eddressCode = eddressCode;
    this.showInApp = showInApp;
    this.isDeleted = isDeleted;
    this.messagingUid = messagingUid;
    this.timeInterval = timeInterval;
    this.commission = commission;
    this.timeZone = timeZone;
    this.deliverWith = deliverWith;
    this.isSchedulesEnabledForCollectionsGroupsProducts =
      isSchedulesEnabledForCollectionsGroupsProducts;
    this.openingHours = openingHours;
    this.telegramSubscription = telegramSubscription;
    this.training = training;
    this.active = active;
    this.deleted = deleted;
  }
}

export default Store;

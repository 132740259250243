import storeParser from "./parsers/store/parse-to-store";
import { data as _fakeStores } from "./dummy-data/_fake.stores";
// import storesParser from "./parsers/parse-to-stores";

const ENDPOINT = "stores";

const getAll =
  ({ client, authHeader }) =>
  async ({ accessToken }) => {
    // return new Promise((resolve, reject) => {
    //   const parsedData = _fakeStores.map(storeParser.parseToStore);
    //   resolve(parsedData);
    //   reject(new Error("Error"));
    // });

    const response = await client.get(`/${ENDPOINT}`, {
      headers: authHeader(accessToken),
    });

    const stores = response.data.map(storeParser.parseToStore);

    return stores;
  };

// const get = ({ client, authHeader }) => async (id) => {
//   const response = await client.get(`/${ENDPOINT}/${id}`, {
//     headers: authHeader(),
//   });

//   const store = storeParser.parseToStore(response.data);

//   return store;
// };

// const create = ({ client, authHeader }) => (data) => {
//   return client.post(`/${ENDPOINT}`, data, { headers: authHeader() });
// };

// const update = ({ client, authHeader }) => (id, data) => {
//   return client.put(`/${ENDPOINT}/${id}`, data, { headers: authHeader() });
// };

// const remove = ({ client, authHeader }) => (id) => {
//   return client.delete(`/${ENDPOINT}/${id}`, { headers: authHeader() });
// };

// const removeAll = ({ client, authHeader }) => () => {
//   return client.delete(`/${ENDPOINT}`, { headers: authHeader() });
// };

// const unremove = ({ client, authHeader }) => (id) => {
//   return client.put(`/${ENDPOINT}/${id}/undelete`, null, {
//     headers: authHeader(),
//   });
// };

export default (client, authHeader) =>
  class StoreService {
    getAll = getAll({ client, authHeader });
    // get = get({ client, authHeader });
    // create = create({ client, authHeader });
    // update = update({ client, authHeader });
    // remove = remove({ client, authHeader });
    // removeAll = removeAll({ client, authHeader });
    // unremove = unremove({ client, authHeader });
  };

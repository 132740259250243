import Inventory from "../../../../model/point-of-sale/inventory/inventory.model";

const parseToInventory = (pointOfSaleStore) => {
  return new Inventory({
    inventory: pointOfSaleStore.inventory,
  });
};

export default {
  parseToInventory,
};

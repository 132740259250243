import orderParser from "./parsers/order/parse-to-point-of-sale-order";

const ENDPOINT = "orders";

const create =
  ({ client, authHeader }) =>
  async ({ checkout, employee, accessToken }) => {

    // return new Promise((resolve, reject) => {
    //   const parsedData = orderParser.parseToOrder(checkout, employee);
    //   console.log(parsedData);
    //   resolve({...parsedData, id: "abcdefgabc"});
    //   reject(new Error("Error"));
    // });

    const parsedData = orderParser.parseToOrder(checkout, employee);
    const response = await client.post(`/${ENDPOINT}`, parsedData, {
      headers: authHeader(accessToken),
    });

    const data = response.data;

    if (data.message !== "valid") {
      throw new Error("Error al agregar la orden");
    }
    return data.data;
  };

export default (client, authHeader) =>
  class OrderService {
    create = create({ client, authHeader });

    // get = get({ client, authHeader });
    // create = create({ client, authHeader });
    // update = update({ client, authHeader });
    // remove = remove({ client, authHeader });
    // removeAll = removeAll({ client, authHeader });
    // unremove = unremove({ client, authHeader });
  };

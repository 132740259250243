import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';

import App from "./App";
import * as serviceWorker from "./serviceWorker";


// const history = createBrowserHistory();

// let prevPath = null;

// // listen and notify Segment of client-side page updates
// history.listen((location) => {
//   if (location.pathname !== prevPath) {
//     prevPath = location.pathname;
//     global.analytics.page();
//   }
// });


ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory
    >
      <App />
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();

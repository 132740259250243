class Customer {
  constructor({
    erpId,
    commerceId,
    commerceUid,
    provider,
    name,
    phone,
    email,
    nit,
    socialReason,
    // platform,
  }) {
    this.erpId = erpId;
    this.commerceId = commerceId;
    this.commerceUid = commerceUid;
    this.provider = provider;
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.nit = nit;
    this.socialReason = socialReason;
    // this.platform = platform;
  }
}

export default Customer;

import Customer from "../../../../model/point-of-sale/customer/customer.model";

const parseToCustomer = (pointOfSaleCustomer) => {
  return new Customer({
    erpId: pointOfSaleCustomer.erpId,
    commerceId: pointOfSaleCustomer.commerceId,
    commerceUid: pointOfSaleCustomer.commerceUid,
    provider: pointOfSaleCustomer.provider,
    name: pointOfSaleCustomer.name,
    phone: pointOfSaleCustomer.phone,
    email: pointOfSaleCustomer.email,
    nit: pointOfSaleCustomer.nit,
    socialReason: pointOfSaleCustomer.socialReason,
    // platform: pointOfSaleCustomer.platform,
  });
};

export default {
  parseToCustomer,
};

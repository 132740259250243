const PROVIDERS = {
  ERP: "ERP",
  ECOMMERCE: "ECOMMERCE",
};

const parseToPointOfSaleInvoice = ({
  customer,
  selectedCustomer,
  selectedEmployee,
  paymentMethod,
}) => {
  let data = {
    erpEmployeeId: selectedEmployee.id,
    paymentMethod: paymentMethod.toUpperCase(),
  };
  if (!customer) {
    data.isNewUser = true;
    data.name = "SN";
    data.billingName = "SN";
    data.nit = "0";
  } else {
    if (customer.provider === PROVIDERS.ECOMMERCE) {
      data.useOrderCustomer = true;
      data.provider = customer.provider;
      data.isNewUser = false;

      if (customer.socialReason !== selectedCustomer.socialReason) {
        data.billingName = customer.socialReason;
      }
      if (customer.nit !== selectedCustomer.nit) {
        data.nit = customer.nit;
      }
      if (customer.name !== selectedCustomer.name) {
        data.name = customer.name;
      }
      if (customer.phone !== selectedCustomer.phone) {
        data.phoneNumber = customer.phone;
      }
    } else if (customer.provider === PROVIDERS.ERP) {
      data.useOrderCustomer = false;
      data.erpCustomerId = customer.erpId;
      data.isNewUser = false;
      // data.billingName = customer.socialReason || 'SN';
      // data.nit = customer.nit || '0';
      // data.name = customer.name || 'SN';
      if (customer.socialReason !== selectedCustomer.socialReason) {
        data.billingName = customer.socialReason;
      }
      if (customer.nit !== selectedCustomer.nit) {
        data.nit = customer.nit;
      }
      if (customer.name !== selectedCustomer.name) {
        data.name = customer.name;
      }
      if (customer.phone !== selectedCustomer.phone) {
        data.phoneNumber = customer.phone;
      }
      // if (data.billingName || data.nit || data.name) {
      //   data.useOrderCustomer = true;
      // }
    } else {
      data.isNewUser = true;
      data.billingName = customer.socialReason || "SN";
      data.nit = customer.nit || "0";
      data.name = customer.name || "SN";
      if (customer.phone) {
        data.phoneNumber = customer.phone;
      }
    }
  }
  return data;
};

export default {
  parseToPointOfSaleInvoice,
};

class Product {
  constructor({
    tenantUid,
    operationUid,
    id,
    thirdPartyUid,
    category,
    customizationItems,
    recommendationTags,
    stockThreshold,
    size,
    brandName,
    brandImage,
    sku,
    label,
    imageUrl,
    thumbnailUrl,
    unit,
    itemViewType,
    neverRecommend,
    recommendationLevel,
    group,
    itemType,
    weight,
    outOfStock,
    qtyPerUnit,
    sortOrder,
    minQty,
    price,
    discountInPercent,
    strikedPrice,
    isPriceOverridden,
    discountLabel,
    vatFree,
    requiresLegalAge,
    ratingEnabled,
    hasSpecialInstructions,
    updatedOn,
    createdOn,
    productCollections,
    withReminder,
    showWeight,
    isGiftWrapProduct,
    hasPriceRange,
    hasSchedules,
    isPublished,
    isActive,
    description,
  }) {
    const getPrice = () => {
      if (strikedPrice) return strikedPrice;
      return price;
    };
    const getDiscountPrice = () => {
      if (strikedPrice) return price;
      return null;
    };
    this.tenantUid = tenantUid;
    this.operationUid = operationUid;
    this.id = id;
    this.thirdPartyUid = thirdPartyUid;
    this.category = category;
    this.customizationItems = customizationItems;
    this.recommendationTags = recommendationTags;
    this.stockThreshold = stockThreshold;
    this.size = size;
    this.brandName = brandName;
    this.brandImage = brandImage;
    this.sku = sku;
    this.label = label;
    this.imageUrl = imageUrl;
    this.thumbnailUrl = thumbnailUrl;
    this.unit = unit;
    this.itemViewType = itemViewType;
    this.neverRecommend = neverRecommend;
    this.recommendationLevel = recommendationLevel;
    this.group = group;
    this.itemType = itemType;
    this.weight = weight;
    this.outOfStock = outOfStock;
    this.qtyPerUnit = qtyPerUnit;
    this.sortOrder = sortOrder;
    this.minQty = minQty;
    this.price = price;
    this.discountInPercent = discountInPercent;
    this.strikedPrice = strikedPrice;
    this.isPriceOverridden = isPriceOverridden;
    this.discountLabel = discountLabel;
    this.vatFree = vatFree;
    this.requiresLegalAge = requiresLegalAge;
    this.ratingEnabled = ratingEnabled;
    this.hasSpecialInstructions = hasSpecialInstructions;
    this.updatedOn = updatedOn;
    this.createdOn = createdOn;
    this.productCollections = productCollections;
    this.withReminder = withReminder;
    this.showWeight = showWeight;
    this.isGiftWrapProduct = isGiftWrapProduct;
    this.hasPriceRange = hasPriceRange;
    this.hasSchedules = hasSchedules;
    this.isPublished = isPublished;
    this.isActive = isActive;
    this.getPrice = getPrice;
    this.getDiscountPrice = getDiscountPrice;
    this.description = description;
  }
}

export default Product;

class Employee {
  constructor({
    id,
    name,
  }) {
    this.id = id;
    this.name = name;
  }
}

export default Employee;
